import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import _ from 'lodash';
import { PROJECT_CARBON_FIELD } from '@/shared/constants';

const MAIN_CHARACTERISTIC_FIELD = `
  id
  description
`;

export const LOCATION_FIELD = `
  id
  createdAt
  updatedAt
  projectId
  type
  type1
  kind
  status
  selected
  surface
  setName
  setName1
  setName2
  setName3
  scriptName
  description
  note
  foundOn
  mapPicture
  googleMapLink
  toilets
  trash
  fireExtinguisher
  catering
  addressId1
  addressId2
  addressId3
  addressId4
  addressId5
  addressId6
  addressId7
  addressId8
  addressId9
  addressId10
  addressId11
  addressId12
  addressId13
  addressId14
  ownerSupplierId
  dayRateFee
  dayRateSettingUpFee
  downPaiement
  total
  paiementType
  parkingPermit
  getInDate
  getOutDate
  shootingStartDate
  shootingEndDate
  elecSupplier
  elecMeterStart
  elecMeterEnd
  elecMeterStartPict
  elecMeterEndPict
  gasMeterStart
  gasMeterEnd
  gasMeterStartPict
  gasMeterEndPict
  waterMeterStart
  waterMeterEnd
  waterMeterStartPict
  waterMeterEndPict
  clientId
  numberOfPicture
  filmingArea
  mainCharacteristic
  totNbOfDaysOccupation
  totNbOfManDays
  energySavingPlan
  nbOfAirConditioners
  greenEnergySupplier
  recycledPaper
  sortedWaste
  generatorFuelType
  generatorVolumeUsed
  generatorPict
  waterSupplier
  gasSupplier
  paidByProduction
  consumptionElectricityTotal
  consumptionGasTotal
  consumptionWaterTotal
  volumeTotalDieselUsed
  volumeTotalGasUsed
  volumeTotalGasolineUlUsed
  volumeTotalHydrogenUsed
  address1 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address2 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address3 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address4 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address5 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address6 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address7 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address8 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address9 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address10 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address11 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address12 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address13 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  address14 {
    name
    street
    number
    box
    zip
    state
    city
    country
    countryCode
    countryKey
    other
    lat
    lng
  }
  owner {
    id
    createdAt
    updatedAt
    licenseId
    name
    webSite
    externalId
    companyType
    email
    vat
    phone
    info
    addressId
    contactName
    contactFunction
    nationalRegistryNumber
    type
    vatRate
    companyTypeName
    typeName
  }
  images
  imageName
  documents
  documentAttachmentXid
  computerEquipments {
    id
    createdAt
    updatedAt
    locationId
    type
    description
    quantity
  }
  documentAttachment {
    id
    createdAt
    updatedAt
    xid
    xidThumb
    ext
    parentType
    parentSubType
    parentID
    publisherID
    userID
    projectID
    originalFileName
    licenceId
    attachToOffer
    attachToOrder
    attachToInvoice
  }
  locationDates {
    id
    date
  }
  carbon {
    ${PROJECT_CARBON_FIELD}
  }
  isHidden
  canNotBeHidden
`;

export const newLocation = async (clientId = null) => {
	const mutation = gql`
    mutation ($clientId: ID) {
      NewLocation(ClientId: $clientId) {
        ${LOCATION_FIELD}
      }
    }
  `;

	let record = null;
	if (!_.isNil(clientId) && clientId > 0) {
		record = await apollo.mutate({
			mutation,
			variables: {
				clientId: parseInt(clientId, 10)
			}
		});
	} else {
		record = await apollo.mutate({
			mutation
		});
	}

	return record.data.NewLocation;
};

export const getLocations = async (variables = {}) => {
	try {
		const query = gql`
      query ($clientId: ID, $startDate: String, $endDate: String, $Status: ID) {
        GetProjectLocationList(ClientId: $clientId, StrDate: $startDate, EndDate: $endDate, Status: $Status, RemovePremise: true) {
          ${LOCATION_FIELD}
        }
      }
    `;

		const {
			data: { GetProjectLocationList }
		} = await apollo.query({
			query,
			variables,
			fetchPolicy: 'no-cache'
		});

		return GetProjectLocationList;
	} catch (e) {
		console.log(e);
	}
};

export const getLocation = async (id) => {
	try {
		const query = gql`
      query ($LocationId: ID!) {
        GetLocation(LocationId: $LocationId) {
          ${LOCATION_FIELD}
        }
      }
    `;
		const {
			data: { GetLocation }
		} = await apollo.query({
			query,
			variables: {
				LocationId: parseInt(id, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetLocation;
	} catch (e) {
		console.log({ e });
	}
};

export const updateLocation = async (id, location) => {
	if (_.isNil(id)) return;
	try {
		const mutation = gql`
      mutation ($LocationId: ID!, $UpdatedLocation: LocationInput!) {
        UpdLocation(LocationId: $LocationId, UpdatedLocation: $UpdatedLocation) {
          ${LOCATION_FIELD}
        }
      }
    `;

		const {
			data: { UpdLocation }
		} = await apollo.mutate({
			mutation,
			variables: {
				LocationId: parseInt(id, 10),
				UpdatedLocation: location
			}
		});

		return UpdLocation;
	} catch (e) {
		console.log({ e });
	}
};

export const updOrDeleteLocation = async (id, date) => {
	if (_.isNil(id)) return;
	try {
		const mutation = gql`
			mutation ($NewLocId: ID!, $Date: String!) {
				UpdOrDeleteLocation(NewLocId: $NewLocId, Date: $Date)
			}
		`;

		const {
			data: { UpdOrDeleteLocation }
		} = await apollo.mutate({
			mutation,
			variables: {
				NewLocId: parseInt(id, 10),
				Date: date
			}
		});

		return UpdOrDeleteLocation;
	} catch (e) {
		console.log({ e });
	}
};

export const deleteLocation = async (id) => {
	if (_.isNil(id)) return;
	try {
		const mutation = gql`
			mutation ($LocationId: ID!) {
				DelLocation(LocationId: $LocationId)
			}
		`;
		const {
			data: { DelLocation }
		} = await apollo.mutate({
			mutation,
			variables: {
				LocationId: parseInt(id)
			}
		});

		return DelLocation;
	} catch (e) {
		console.log({ e });
	}
};

export const deleteLocations = async (ids) => {
	if (ids.length === 0) return;
	try {
		const mutation = gql`
			mutation ($LocationsId: [ID!]!) {
				DelLocations(LocationsId: $LocationsId)
			}
		`;
		const {
			data: { DelLocations }
		} = await apollo.mutate({
			mutation,
			variables: {
				LocationsId: ids
			}
		});

		return DelLocations;
	} catch (e) {
		console.log({ e });
	}
};

export const getLocationMainCharacteristic = async () => {
	try {
		const QUERY = gql`
        query {
          GetLocationMainCharacteristic {
            ${MAIN_CHARACTERISTIC_FIELD}
          }
        }
    `;

		const {
			data: { GetLocationMainCharacteristic }
		} = await apollo.query({
			query: QUERY,
			fetchPolicy: 'no-cache'
		});

		return GetLocationMainCharacteristic;
	} catch (e) {
		console.log(e);
	}
};

export const delMainCharacteristic = async (mainCharacteristic = '') => {
	const MUTATION = gql`
		mutation ($mainCharacteristic: String!) {
			DelMainCharacteristic(MainCharacteristic: $mainCharacteristic)
		}
	`;

	const {
		data: { DelMainCharacteristic }
	} = await apollo.mutate({
		mutation: MUTATION,
		variables: {
			mainCharacteristic
		},
		fetchPolicy: 'no-cache'
	});

	return DelMainCharacteristic;
};
